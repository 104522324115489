import { Link, useRouteError } from "@remix-run/react";
import Logo from "./logo";
import { track } from "~/analytics/track.client";
import { useEffect } from "react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

export function ErrorBoundary() {
  const error = useRouteError() as Error & { status?: number };
  captureRemixErrorBoundaryError(error);
  const is404 = error?.status === 404;
  const msg = is404 ? "Page not found" : "Oops, something went wrong! Error is recorded and will be fixed soon.";

  useEffect(() => {
    track("error", { error: error.message, stack: error.stack });
  }, [error]);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-7">
      <Logo className={"w-24"} />
      <h1 className={"text-2xl "}>{msg}</h1>

      <Link to="/" unstable_viewTransition className="text-keenTextBright underline">
        Go back to home page
      </Link>
      {(error.message || error.stack) && (
        <details className="mt-4 text-sm">
          <summary className="mx-auto mb-2 ">Technical Details</summary>
          <div className="max-w-[80dvw] overflow-auto  font-mono text-xs">
            <pre className="overflow-auto text-red-500">{error?.message}</pre>
            <pre className="overflow-auto text-red-500">{error?.stack}</pre>
          </div>
        </details>
      )}
    </div>
  );
}
