import { useEffect } from "react";
import * as Sentry from "@sentry/remix";
import { useLocation, useMatches } from "@remix-run/react";
import { useRootContext } from "~/utils/rootContext";

function initializeSentry(dsn: string, app_env: string) {
  Sentry.init({
    environment: app_env,
    dsn,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
  });
}

export function SentryInit() {
  const { ENV } = useRootContext();

  useEffect(() => {
    if (!sessionStorage.getItem("sentryInitialized")) {
      initializeSentry(ENV.SENTRY_DSN, ENV.FACEBUILDER_ENVIRONMENT);
      sessionStorage.setItem("sentryInitialized", "true");
    }
  }, [ENV]);

  return null;
}
