import posthog from "posthog-js";
import { useEffect } from "react";
import { useRootContext } from "~/utils/rootContext";

function initializePosthog(apiKey: string, host: string) {
  posthog.init(apiKey, {
    api_host: "/posthog-proxy",
    ui_host: host,
    person_profiles: "identified_only",
    capture_pageview: false,
  });
}

export function PosthogInit() {
  const { ENV } = useRootContext();

  useEffect(() => {
    if (!sessionStorage.getItem("posthogInitialized")) {
      initializePosthog(ENV.POSTHOG_API_KEY, ENV.POSTHOG_HOST);
      sessionStorage.setItem("posthogInitialized", "true");
    }
  }, [ENV]);

  return null;
}
