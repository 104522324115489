import { NavLink } from "@remix-run/react";
import { ReactNode } from "react";
import { cn } from "~/utils";
import { BUILD_DATE, BUILD_COMMIT } from "~/utils/buildStamp";

interface FooterProps {
  hideAuthorizedLinks: boolean;
  additionalFooter?: ReactNode;
  className?: string;
}

const Footer = ({ hideAuthorizedLinks, additionalFooter, ...props }: FooterProps) => {
  const date = new Date(parseInt(BUILD_DATE, 10));
  // constant TZ to avoing hydration errors
  const adjustedDate = new Date(date.getTime() + 60 * 60 * 1000); // adjust to CET
  const formattedDate = adjustedDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const buildStamp = `${BUILD_COMMIT} ${formattedDate} (CET)`;

  return (
    <footer
      {...props}
      className={cn(
        "sticky bottom-0 z-10 flex justify-between gap-2 text-nowrap px-7 py-3 font-thin text-keenTextBright  backdrop-blur lg:order-3 lg:col-span-2 lg:gap-5 lg:py-5 ",
        props.className,
      )}
    >
      <NavLink
        to="/all-heads"
        end
        unstable_viewTransition
        className={({ isActive, isPending }) =>
          cn("overflow-hidden text-ellipsis text-nowrap underline hover:bg-slate-400/40", {
            "text-keenTextDark no-underline": isActive,
            "animate-pulse cursor-wait": isPending,
            hidden: hideAuthorizedLinks,
          })
        }
      >
        Heads archive
      </NavLink>

      <NavLink
        to="/"
        end
        unstable_viewTransition
        className={({ isActive, isPending }) =>
          cn("overflow-hidden text-ellipsis text-nowrap underline hover:bg-slate-400/40", {
            "text-keenTextDark no-underline": isActive,
            "animate-pulse cursor-wait": isPending,
            hidden: hideAuthorizedLinks,
          })
        }
      >
        Create new
      </NavLink>

      {additionalFooter}
      {!additionalFooter && <div className="grow"></div>}

      <div className="flex items-center justify-end gap-5 overflow-hidden text-ellipsis text-keenTextMuted">
        <a
          className="overflow-hidden text-ellipsis underline hover:bg-slate-400/40 lg:block "
          href="https://keentools.io/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span className="hidden lg:inline">©KeenTools</span>
          <span className="lg:hidden">Info</span>
        </a>

        <a
          href="https://link.keentools.io/eula"
          target="_blank"
          rel="noreferrer noopener"
          className="hidden overflow-hidden text-ellipsis underline hover:bg-slate-400/40 lg:inline"
        >
          EULA
        </a>
        <a
          href="https://link.keentools.io/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
          className="hidden overflow-hidden text-ellipsis underline hover:bg-slate-400/40 lg:inline"
        >
          Privacy Policy
        </a>
      </div>
      <span className="rtl absolute bottom-0  right-0 origin-top-right rotate-90 text-xs text-keenDarkBg">
        {buildStamp}
      </span>
    </footer>
  );
};

export default Footer;
