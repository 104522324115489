import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation,
} from "@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";

import stylesheet from "~/globals.css?url";
import { PUBLIC_ENV } from "./env.server";
import { ReactNode, useEffect, useMemo, useState } from "react";
import Footer from "./components/Footer";
import { cn } from "./utils";
import posthog from "posthog-js";
import { PosthogInit } from "~/analytics/PostHogInit";
import { getDistinctId } from "~/analytics/track.server";
import { identify } from "~/analytics/track.client";
import { SentryInit } from "~/components/SentryInit";
import { RootContext } from "~/utils/rootContext";

export { ErrorBoundary } from "~/components/error-boundary";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  {
    rel: "preload",
    href: "/fonts/OpenSans-VariableFont_wdth,wght.ttf",
    as: "font",
    type: "font/ttf",
    crossOrigin: "anonymous",
  },
  {
    rel: "icon",
    href: "/favicon.svg",
    type: "image/svg+xml",
  },
];

export const shouldRevalidate = () => false;

export async function loader({ request }: LoaderFunctionArgs) {
  const distinctId = getDistinctId(request);

  return json({
    ENV: PUBLIC_ENV,
    distinctId,
  });
}

export const meta: MetaFunction = () => {
  return [
    { title: "KeenTools face builder" },
    { name: "description", content: "KeenTools FaceBulder Online: upload photos — get 3D head." },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { state } = useNavigation();
  const isIdle = state === "idle";

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body
        className={cn("text-text-bright dark flex min-h-screen flex-col overflow-x-hidden bg-keenDarkBg text-base ", {
          "cursor-wait": !isIdle,
        })}
      >
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export type RootHandle = {
  setFooter: (node: ReactNode) => void;
  hideAuthorizedLinks: () => void;
  showAuthorizedLinks: () => void;
};

export default function App() {
  const [isAuthorizedShown, setIsAuthorizedShown] = useState<boolean>(true);
  const [footer, setFooter] = useState<ReactNode | null>(null);
  const location = useLocation();
  const data = useLoaderData<typeof loader>();
  const { ENV, distinctId } = data;

  useEffect(() => {
    identify(distinctId);
  }, [distinctId]);

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  const outletContext = useMemo(
    () =>
      ({
        setFooter,
        hideAuthorizedLinks: () => setIsAuthorizedShown(false),
        showAuthorizedLinks: () => setIsAuthorizedShown(true),
      }) as RootHandle,
    [setFooter, setIsAuthorizedShown],
  );

  return (
    <>
      <RootContext.Provider value={{ ENV }}>
        <div className="mx-auto flex min-h-[100dvh] w-full flex-col lg:max-w-[170dvh]">
          <main className="flex grow flex-col items-center">
            <Outlet context={outletContext} />
          </main>
          <Footer additionalFooter={footer} hideAuthorizedLinks={!isAuthorizedShown} />
        </div>
        <PosthogInit />
        <SentryInit />
      </RootContext.Provider>
    </>
  );
}
